.tn-precart-response-message {
    font-size: 110%;
    color: #444;
    padding: 20px 0;
    br:first-of-type {
		display: block;
	}
    hr {
        height: 1px transparent !important;
        background-color: transparent !important;
        border-top: 1px solid #ccc !important;
    }
}
