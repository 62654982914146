
.tn-account-login-page {
	h2 {
		font-weight: bold;
		color: color(blue);
	}
}

.tn-timer {
	@extend .animated;
	@extend .fadeInUp;

	@media (min-width: $screen-xs-max) {
		color: #437cd2;
		width: 180px;
		display: block;
		background: #fff;
		padding: 10px;
		font-size: 87% !important;
		letter-spacing: 0.5px;
		border: 2px solid #dfe0ef;
	}
}

.active-cart, 
.sample-icons .active-cart:after, 
.tn-subnav-component .tn-cart-link.tn-active .tn-link .tn-icon:after {
	content:"\f291" !important;
}

