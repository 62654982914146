/*------------------------------------*\
    Hamburger Menu Icon & Animation
\*------------------------------------*/


.c-hamburger {
  width: 24px;
  height: 18px;
  position: relative;
  z-index: 100;
  margin: 0px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}
.c-hamburger:hover span {
    background: #999;
  }

.c-hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: color(white);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.c-hamburger span:nth-child(1) {
  top: 0px;
}

.c-hamburger span:nth-child(2), .c-hamburger span:nth-child(3) {
  top: 6px;
}

.c-hamburger span:nth-child(4) {
  top: 12px;
}

.nav-open .c-hamburger span:nth-child(1) {
  top: 6px;
  width: 0%;
  left: 50%;
}

.nav-open .c-hamburger span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav-open .c-hamburger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav-open .c-hamburger span:nth-child(4) {
  top: 6px;
  width: 0%;
  left: 50%;
}
