
.tn-cart-details-page {
	.tn-heading {
		color: color(blue);
	}
	.tn-cart-component {
		background: #fafafa;
    	padding: 20px;
    	border: 1px solid #dfdfdf;
		border-radius: 3px;
		margin-bottom: 20px;
	}
	.tn-cart-totals {
		margin: 0;
	}
	.tn-cart-item h6 {
		font-size: 140%;
    	margin-bottom: 0;
	}
}
