
.tn-pre-cart-page {
	h2 {
		font-size: 200%;
		color:color(blue);
	}
	table {
		display: block;
		border: none;
		margin: 20px 0;
	}
	tbody {
		display: block;
		width: 100%;
	}
	tr {
		display: block;
		@media (min-width: $screen-xs-max) {
			display: flex;
		}
	}
	td {
		border: 1px solid #ccc;
		vertical-align: top;
		padding: 20px;
		width: 100% !important;
		display: block;
		@media (min-width: $screen-xs-max) {
			width: 33% !important;
		}
		br:first-of-type {
			display: none;
		}
	}
	img {
		width: 100%;
		height: auto;
		margin-bottom: 20px;
	}
	b {
		display: block;
		margin-bottom: 10px;
		color: color(blue);
		font-size: 24px;
	}
	br {
		display: none;
	}
	.btnStyle {
		@extend .btn;
		@extend .btn-primary;
		clear: both;
	}
}

.tn-special-seat-notification-component {
	table {
		thead {
			display:block;
			width:100%;
			th {
				width:33%;
			}
		}
	}
}
