
.max-width {
    @media (min-width: $screen-xs-max) {
        width: 80%;
    }
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

.global-content-wrap {
    margin-top: 60px; // push down below header
}

.content-wrap {
    padding-bottom: 50px;
    min-height: 90vh;
}


// loading indicator

.tn-syos-busy-indicator {
    @extend .animated;
    @extend .fadeInUp;
    .tn-syos-busy-indicator__message {
        font-style: normal;
    }
}


@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}

