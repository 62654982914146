body {
    font-family:'fira-sans', Helvetica, Arial, sans-serif;
}

.tn-event-listing-mode-tab-nav__list {
    .tn-event-listing-mode-tab-nav__list-item {
        color:color(blue) !important;
        font-size:1em !important;
    }
}

.tn-event-listing-mode-tab-nav__list, .tn-events-keyword-filter--event-listing-multi-mode {
    margin-bottom:0 !important;
}

.tn-event-listing-mode-tab-nav__list-item, .tn-event-listing-mode-nav-list-view {
    padding:5px 10px !important;
    font-size:16px !important;
    @media (min-width: $screen-xs-max) {
        padding:10px 15px !important;
    }
}

.tn-events-calendar-mode-tab-nav {
    top:20px !important;
    right:20px !important;
}

.tn-event-listing-mode-tab-nav__list--secondary {
    top:20px !important;
    right:20px !important;
}

.tn-events-calendar-view, .tn-events-list-view {
    padding:20px 0 !important;
}

.tn-btn-datepicker__icon-container {
    .glyphicon {
        color:color(blue);
    }
}

.tn-events-keyword-filter--event-listing-multi-mode {
    margin-bottom:20px !important;
}

section.tn-header-component {
    h1.tn-heading {
        font-size:36px !important;
        margin-bottom:0.8em !important;
    }
    h1 {
        margin-bottom:0.5em !important;
    }
    ul {
        font-size:20px !important;
    }
}

.tn-btn-datepicker__btn {
    @media (min-width: $screen-xs-max) and (max-width: $screen-lg) {
        max-width:180px;
    }
}


.tn-btn-datepicker__btn-period-prev-next--btn-next, .tn-btn-datepicker--with-range-btns .tn-btn-datepicker__btn {
    border-radius:0 !important;
}

.tn-prod-list-item__property--img-container {
    grid-row:1/span 3 !important;
    margin:0 10px 0 0 !important;
}

.tn-prod-list-item {
    @media (max-width: $screen-xs-max) {
        background-color:#F1F1F1;
        padding:20px;
    }
    h4.tn-prod-list-item__property--heading {
        @media (min-width: $screen-xs-max) {
            font-size:1.7em;
        }
    }
}
