.c-global-header {
    font-weight: 400;
    color: color(white);
    background-color:color(off-white);
    width: 100%;
    z-index: 100;
    @media (min-width: $screen-xs-max) {
        padding: 20px 0;
        box-shadow: 0 0 15px rgba(0,0,0,0.2);
    }
    .max-width {
        position: relative;
    }
    .c-global-header-logo {
        display: inline-block;
        margin:10px 0;
        img {
            width:140px;
        }
    }
}

.c-global-header__back-link {
    color: #333333;
    float:right;
    font-weight:bold;
    padding: 10px 15px;
    margin-top:20px;
    transform: translateY(-50%);
    text-decoration:none;
    @media (min-width: $screen-xs-max) {
        font-size:0.7em;
        margin-top:50px;
    }
    @media (max-width: $screen-xs-max) {
        font-size:0.8em;
        margin-top:30px;
    }
    &:hover {
        background:#DBE8EA;
        color:color(blue);
        text-decoration:none;
    }
}
