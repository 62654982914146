
// subnav bar

.tn-subnav-component {
    padding: 8px;
    background-color: $mercury;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    @media (min-width: $screen-xs-max) {
        width: 100%;
        a {
            color: color(blue);
            &:hover {
                opacity: 0.7;
            }
        }
    }
}
.tn-subnav-component .tn-login-link .tn-link {
    display: inline-block;
}

.global-content-wrap .tn-subnav-component {
    #tn-apply-promo {
        @include form-inline();

    }
    .tn-promo-box {
        @media (min-width: $screen-xs-max) {
            margin-right:0 !important;
        }
        button.tn-subnav-promo-button {
            @extend .btn;
            @extend .btn-primary;
            @extend .btn-sm;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            text-decoration: none;
            font-size: 0;
            img {
                display: none;
            }
            &:after {
                content: ">";
                font-size: 16px;
            }
        }
    }
    .tn-subnav-promo-code {
        display: inline-block;
        max-width: 140px;
        @extend .form-control;
        height: 36px;
        padding: 5px 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        @media (max-width: $screen-xs-max) {
            font-size: 14px;
        }
    }
}


.tn-syos-mobile-cart-controls__summary-subtotal {
    margin-bottom: 0;
}

.tn-syos-busy-indicator {
    @extend .animated;
    @extend .fadeInUp;
    .tn-syos-busy-indicator__message {
        font-style: normal;
    }
}


@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}
