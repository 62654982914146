
.tn-events-detail-page {
	.tn-prod-season-header__title h6 {
	    font-size: 140%;
	    color: color(blue);
	}
	.tn-ticketing-mode-change {
		margin:30px 0 !important;
		text-align: right;
		@media (max-width: $screen-xs-max) {
			height: 120px;
			position: relative;
			.tn-ticketing-mode-change__content, .input-group {
				width:100%;
			}
		}
		.input-group {
			max-width: 300px;
			margin-top: -50px;
			@media (max-width: $screen-xs-max) {
				bottom: 0;
				position: absolute;
				max-width:100%;
			}
		}
		.tn-ticketing-mode-change__content {
			font-style: normal;
			font-weight: bold;
			@media (max-width: $screen-xs-max) {
				text-align:center;
			}
			a {
				p {
					margin-bottom:0;
				}
			}
		}
		.tn-additional-events__date-select {
			width:350px;
		}
	}
	.tn-prod-season-header {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.tn-prod-season-header__title-and-image-container {
		margin-top: 0;
	}
	.tn-prod-season-header__date-container {
		font-weight: bold;
		margin-bottom:20px !important;
	}
	.tn-syos-mobile-cart-controls__summary-subtotal {
		margin-bottom: 0;
	}
	.tn-syos-screen-button {
		font-size: 90%;
		border-radius: 3px;
		background-color: color(blue);
		transition: all 200ms ease;
		&:hover {
			background-color: darken($blue, 10%);
			transform: scale(1.05, 1.05);
		}
	}
	.tn-syos-screen-button__section-heading {
		font-weight: bold;
	}
	.tn-syos-seat-info__header td {
		font-weight: bold;
	}
	.tn-syos-seat-info__price-type--button, .tn-syos-seat-info__price-type-list-item {
		font-size: 80%;
	}
	.tn-syos-mobile-cart-controls__summary {
		@media (max-width: $screen-xs-max) {
			font-size: 14px;
		}
	}
	.tn-syos__btn-add-to-cart {
		font-weight: bold;
		background-color: color(blue);
		text-transform: initial;
		@media (max-width: $screen-xs-max) {
			font-size: 14px;
		}
	}
	.tn-syos-screen-container {
		@media (max-width: $screen-xs-max) {
			padding: 15px;
			text-align: center;
			border: 1px solid #ddd;
		}
	}
}

// the seat info pop up
.tn-syos-price-type-selector .tn-syos-price-type-selector__content-container,
.tn-syos-seat-info-popover .tn-syos-seat-info-popover__content-container {
	box-shadow: 0 0 8px rgba(0,0,0,0.4);
}

// view seats toggle
.tn-syos-cart__content-container {
	background: #eee;
}

.tn-prod-season-header__title-and-image-container {
	margin-bottom:20px;
}

// hide the bits we don't need

.tn-event-detail__additional-events-container {
    display: none;
}
.tn-event-detail__performance-details-container {
    display: none;
}
.tn-prod-season-header__description-container {
	display: none;
}

.tn-events-detail-page {
	.tn-event-detail__performance-details-container {
		display: block;
		padding:20px;
		p {
			margin-bottom:10px;
			&.tn-event-detail__unavailable-text {
				font-size:1.5em;
				font-weight:bold;
			}
		}
	}
}
